import "./style.scss";

const panoContainer = document.querySelectorAll(".wp-block-bpgb-panorama");
Object.values(panoContainer).map((pano) => {
  const panoEl = document.getElementById(pano.id);
  const {
    panoType,
    panoImage,
    panoVideo,
    autoRotate,
    rotateSpeed,
    hideControl,
    autoPlay,
    videoMute,
    videoLoop,
  } = JSON.parse(panoEl.dataset.panorama);

  const panoContainer = document.querySelector(`#${pano.id} .bpgb_panorama`);

  //Video options
  if (panoType === "video") {
    const videoSource = panoVideo?.id ? panoVideo.url : "";
    const panoramaVideo = new PANOLENS.VideoPanorama(videoSource, {
      autoplay: autoPlay,
      loop: videoLoop,
      muted: videoMute,
    });

    const panoramaViewer = new PANOLENS.Viewer({
      container: panoContainer,
      controlBar: hideControl,
    });
    panoramaViewer.add(panoramaVideo);
  } else {
    const imageSource = panoImage?.id ? panoImage.url : [];
    const panorama = new PANOLENS.ImagePanorama(imageSource);
    const viewer = new PANOLENS.Viewer({
      container: panoContainer,
      autoRotate,
      autoRotateSpeed: rotateSpeed,
      controlBar: hideControl,
    });
    viewer.add(panorama);
  }
});
